<template>
  <div
    style="width: 100%"
    class="product-page overflow-hidden flex-1 overflow-hidden"
  >
    <v-sheet class="px-6 pb-8" style="width: 100%">
      <v-container v-if="this.product !== ''" fluid class="product-info px-8">
        <v-row>
          <v-dialog
            v-model="dialog"
            max-width="900"
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="dark">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>${{ "Icon16Close" }}</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>
              <div>
                <v-carousel height="auto" width="500">
                  <v-carousel-item
                    v-for="(item, i) in this.product.assets"
                    :key="i"
                    style="padding-bottom: 50px"
                  >
                    <v-img
                      max-height="900"
                      class="product__img ma-1"
                      style="border: 1px solid rgba(0, 0, 0, 0.1)"
                      :src="baseurl + item + '/large.png'"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </div>
            </v-card>
          </v-dialog>

          <v-col
            cols="12"
            md="6"
            class="pa-2"
            v-if="Object.keys(this.variantSelectedItem).length <= 0"
          >
            <v-img
              v-if="this.product.assets"
              class="product__img ma-1"
              style="border: 1px solid rgba(0, 0, 0, 0.1)"
              :src="baseurl + this.imageUUID + '/large.png'"
              @click="dialog = true"
            ></v-img>

            <v-img
              v-else
              class="product__img ma-1"
              style="border: 1px solid rgba(0, 0, 0, 0.1)"
              @click="dialog = true"
              src="@/assets/images/no-img.jpg"
            ></v-img>
            <v-item-group mandatory v-if="this.product.assets">
              <v-sheet width="100%" class="d-flex flex-wrap">
                <v-item
                  v-slot="{ active }"
                  v-for="(n, i) in this.product.assets"
                  :key="i"
                  :changeImage="changeImage"
                >
                  <v-card
                    width="20%"
                    min-width="20%"
                    :color="active ? 'grey.lighten-4' : ''"
                    tile
                    flat
                    hover
                    @click="changeImage(n)"
                  >
                    <v-img
                      aspect-ratio="1"
                      class="product__img-thumb ma-1"
                      style="border: 1px solid rgba(0, 0, 0, 0.1)"
                      :src="baseurl + n + '/small.png'"
                    ></v-img>
                  </v-card>
                </v-item>
              </v-sheet>
            </v-item-group>
          </v-col>
          <v-col cols="12" md="6" class="pa-2" v-else>
            <v-img
              v-if="this.variantSelectedItem.assets"
              class="product__img ma-1"
              style="border: 1px solid rgba(0, 0, 0, 0.1)"
              :src="baseurl + this.imageUUID + '/large.png'"
              @click="dialog = true"
            ></v-img>

            <v-img
              v-else
              class="product__img ma-1"
              style="border: 1px solid rgba(0, 0, 0, 0.1)"
              @click="dialog = true"
              src="@/assets/images/no-img.jpg"
            ></v-img>
            <v-item-group mandatory v-if="this.variantSelectedItem.assets">
              <v-sheet width="100%" class="d-flex flex-wrap">
                <v-item
                  v-slot="{ active }"
                  v-for="(n, i) in this.variantSelectedItem.assets"
                  :key="i"
                  :changeImage="changeImage"
                >
                  <v-card
                    width="20%"
                    min-width="20%"
                    :color="active ? 'grey.lighten-4' : ''"
                    tile
                    flat
                    hover
                    @click="changeImage(n)"
                  >
                    <v-img
                      aspect-ratio="1"
                      class="product__img-thumb ma-1"
                      style="border: 1px solid rgba(0, 0, 0, 0.1)"
                      :src="baseurl + n + '/small.png'"
                    ></v-img>
                  </v-card>
                </v-item>
              </v-sheet>
            </v-item-group>
            <!-- {{ this.variantSelectedItem }} -->
          </v-col>

          <v-col cols="12" md="6" class="flex-column">
            <div class="product__badges d-flex">
              <v-chip
                v-if="this.newProduct"
                class="label-new font-weight-bold mr-2 rounded-0 justify-center"
                color="primary"
                tile
                label
                small
              >
                UUTUUS
              </v-chip>
              <v-chip
                v-if="this.discount"
                class="label-discount font-weight-bold mr-2 rounded-0 justify-center"
                color="error"
                label
                small
              >
                -{{ discountPercent }} %
              </v-chip>
            </div>
            <!-- <div class="product-brand mt-8">Merkki</div> -->
            <h2 class="product-brand">
              {{ this.product.name }}
            </h2>
            <div v-if="this.product.categories">
              <v-breadcrumbs
                v-if="
                  this.categoryName(this.product.categories[0]).name.fi !==
                    'error'
                "
                class="px-0 py-0"
                :items="[
                  {
                    text: lang(
                      this.categoryName(this.product.categories[0]).name
                    ),
                    to: {
                      name: 'product-list',
                      params: {
                        category: this.seoUrl(this.product),
                      },
                    },
                  },
                ]"
              />
            </div>
            <div v-if="product.variants">
              <div class="d-flex flex-column">
                <h3 class="mt-6 my-2">Vaihtoehdot</h3>
                <div
                  class="d-flex flex-column"
                  v-for="(items, i) in variantList"
                  :key="i + items"
                >
                  <v-select
                    v-model="variantSelect[i]"
                    :label="variantSelectName(i)"
                    :items="variantItems(i)"
                    @change="fixSelection(i)"
                  ></v-select>
                </div>
              </div>
            </div>

            <div
              class="d-flex pt-4 pr-0 pb-4 flex-wrap flex-column product__cost"
            >
              <div v-if="this.product.details" class="d-flex">
                <h2 class="product-card__cost-value" style="line-height: 1">
                  {{ this.splitPrice }}
                </h2>
                <div
                  v-if="this.splitDecimal !== ''"
                  class="product-card__cost-decimal font-weight-bold"
                  style="line-height: 1"
                >
                  .{{ this.splitDecimal }}
                </div>
                <div class="product-card__currency mr-3" style="line-height: 1">
                  €
                </div>

                <div
                  v-if="this.discount"
                  class="product-card__original-price text-decoration-line-through mr-3"
                  style="line-height: 1"
                >
                  {{ realPrice(product.details.price.value) }} €
                </div>
                <div
                  v-if="this.product.details.tax"
                  class="product-card__currency mr-3"
                  style="line-height: 1"
                >
                  sis. {{ this.product.details.tax.value }} % alv
                </div>
                <div
                  v-else
                  class="product-card__currency mr-3"
                  style="line-height: 1"
                >
                  sis. 24% alv
                </div>
              </div>
            </div>
            <!-- <div
              v-if="this.product.details.in_stock && !this.product.variants"
              class="d-flex pt-4 pr-0 pb-4 flex-wrap flex-column product__cost"
            >
              <h4 v-if="this.product.details.in_stock.value > 0">Varastossa</h4>
              <h4 v-else>Tuote Loppu</h4>
            </div> -->
            <div
              v-if="this.stock && !this.product.variants"
              class="d-flex pt-4 pr-0 pb-4 flex-wrap flex-column product__cost"
            >
              <h4 v-if="this.stock > 0">Varastossa</h4>
              <h4 v-else-if="this.stock <= 0">Tuote Loppu</h4>
            </div>

            <!-- <div v-if="this.selectedProduct">
              <template v-if="this.selectedProduct.in_stock">
                <h4 v-if="this.selectedProduct.in_stock.value > 0">
                  Varastossa
                </h4>
                <h4 v-else>Tuote Loppu</h4>
              </template>
              <template
                v-if="
                  variantDisable === true &&
                    Object.keys(this.variantSelect).length >=
                      Object.keys(variantList).length
                "
              >
                <h4>Tuote Loppu</h4>
              </template>
            </div> -->

            <div v-else-if="this.selectedProduct && this.product.variants">
              <template v-if="this.variantStock">
                <h4 v-if="this.variantStock > 0">
                  Varastossa
                </h4>
                <h4 v-else>Tuote Loppu</h4>
              </template>
              <template
                v-if="
                  variantDisable === true &&
                    Object.keys(this.variantSelect).length >=
                      Object.keys(variantList).length
                "
              >
                <h4>Tuote Loppu</h4>
              </template>
            </div>

            <div v-if="this.product.details.delivery_class">
              <div
                v-if="
                  this.product.details.delivery_class.value === 'ORDER_ONLY'
                "
                class="pb-4"
              >
                <h4>Huom! Tämä tuote on erikoistoimitettava</h4>
              </div>
            </div>

            <v-row style="position: relative">
              <v-col class="flex-grow-0">
                <!-- <StepperInputProduct
                  v-if="!this.product.variants"
                  :item="this.product"
                  @itemCount="itemCount"
                />
                <StepperInputProduct
                  v-if="this.product.variants"
                  :variant="this.selectedProduct"
                  @itemCount="itemCount"
                /> -->
                <StepperInputProduct
                  :key="0"
                  v-if="!this.product.variants"
                  :item="this.product"
                  @itemCount="itemCount"
                />
                <div v-else-if="Object.keys(this.selectedProduct).length > 0">
                  <StepperInputProduct
                    :key="1"
                    :variant="this.selectedProduct"
                    @itemCount="itemCount"
                  />
                </div>
                <div v-else>
                  <StepperInputProduct
                    :key="2"
                    :disable="true"
                    @itemCount="itemCount"
                  ></StepperInputProduct>
                </div>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  rounded
                  large
                  :disabled="variantDisable"
                  block
                  @click="addToCart()"
                >
                  <v-icon>${{ "Icon16Shopping" }}</v-icon>
                  <span class="ml-2">Lisää ostoskoriin</span>
                </v-btn>
              </v-col>
              <v-col class="flex-grow-0">
                <v-icon v-if="!this.$store.state.login.isLoggedIn">
                  ${{ "Icon24Heart" }}
                </v-icon>
                <v-btn
                  text
                  icon
                  large
                  v-else-if="itemInFav(this.product.uuid)"
                  @click="removeFavorite"
                >
                  <v-icon color="red">${{ "Icon24HeartSolid" }}</v-icon>
                </v-btn>
                <v-btn text icon large v-else @click="addFavorite">
                  <v-icon>${{ "Icon24Heart" }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div
              v-if="product.description"
              class="product-page__description my-4 body-2"
            >
              {{ product.description }}
            </div>

            <!-- {{ this.variantSelect }} -->
            <div v-if="!this.product.variants">
              <h3 class="mt-6 my-2">Lisätiedot</h3>

              <template v-for="(item, i) in this.product.details">
                <!-- <div class="d-flex body-2" :key="i" v-if="i !== 'price'"> -->
                <div class="d-flex body-2" :key="i" v-if="hideInfo(i)">
                  <div class="d-flex flex-column" style="width: 100px">
                    <div class="d-flex align-center py-1">
                      <div class="font-weight-bold">
                        {{ lang(variantNames[i]) }}
                        <!-- {{ i }} -->
                      </div>
                      <v-divider class="px-2 mx-1"></v-divider>
                    </div>

                    <div class="d-flex flex-column"></div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="py-1">{{ item.value }} {{ item.unit }}</div>
                  </div>
                </div>
              </template>
            </div>

            <div v-if="this.product.variants">
              <h3 class="mt-6 my-2">Lisätiedot</h3>
            </div>
            <!-- <template v-for="(item, i) in this.variantSelectedItem.details"> -->
            <template v-for="(item, i) in this.selectedProduct">
              <div class="d-flex body-2" :key="i" v-if="hideInfo(i)">
                <div class="d-flex flex-column" style="width: 100px">
                  <div class="d-flex align-center py-1">
                    <div class="font-weight-bold">
                      {{ lang(variantNames[i]) }}
                    </div>
                    <v-divider class="px-2 mx-1"></v-divider>
                  </div>

                  <div class="d-flex flex-column"></div>
                </div>
                <div class="d-flex flex-column">
                  <div class="py-1">{{ item.value }} {{ item.unit }}</div>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>
      <!-- <h2 class="similar-products-heading mb-8">Samankaltaisia tuotteita:</h2> -->
      <ItemRow
        v-if="this.product.categories"
        :uuid="this.product.categories[0]"
        :title="this.product.name"
        perRow="6"
      />
      <RecentItems />
    </v-sheet>
    <Footer />
  </div>
</template>

<style scoped>
.product-brand {
  font-family: "DalaMoa", sans-serif !important;
}
.product-info {
  max-width: 1000px;
}

.keywords-items a:not(:last-child) {
  margin-right: 16px;
}

.v-snack,
.v-snack__wrapper {
  position: absolute;
  top: -50px;
  left: 0;
}

h2.similar-products-heading {
  text-align: center;
  margin-top: 50px;
  font-size: 1.8rem;
  margin-bottom: -40px;
  font-weight: 300;
}

@media only screen and (max-width: 380px) {
  h2.similar-products-heading {
    font-size: 1.2rem;
    margin-bottom: -20px;
  }
}
</style>

<script>
import ItemRow from "@/components/ItemRow";
import Footer from "@/components/Footer";
import RecentItems from "@/components/RecentItems";
import StepperInputProduct from "@/components/StepperInputProduct";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { ItemRow, Footer, RecentItems, StepperInputProduct },

  data() {
    return {
      product: "",
      price: "",
      priceDecimal: "",
      count: 1,
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
      assets: "",
      imageUUID: "",
      dialog: false,
      snackbar: false,
      variants: {},
      hasVariants: false,
      variantSelected: "",
      variantProperties: ["colour", "size_letter_code"],
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product",
      url2: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/category",
      stockUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/stock/",
      stock: 0,
      variantStock: 0,
      // variantOrder: [
      //   "colour",
      //   "size_letter_code",
      //   "width",
      //   // "length",
      //   "height",
      //   "depth",
      //   "weight",
      //   "diameter",
      // ],
      variantOrder: this.$store.state.cms.variantOrder,
      // variantOrder: [
      //   "width",
      //   "diameter",
      //   // "length",
      //   "tekso_size",
      //   // "weight",
      //   "shoe_size",
      //   "measured_size",
      //   "colour",
      //   "size_letter_code",
      //   "numeric_size",
      //   "depth",
      //   "height",
      // ],
      variantNames: {
        colour: {
          fi: "Väri",
          en: "Colour",
        },
        size_letter_code: {
          fi: "Koko",
          en: "Size",
        },
        length: {
          fi: "Pituus",
          en: "Length",
        },
        width: {
          fi: "Leveys",
          en: "Width",
        },
        height: {
          fi: "Korkeus",
          en: "Height",
        },
        depth: {
          fi: "Syvyys",
          en: "Depth",
        },
        weight: {
          fi: "Paino",
          en: "Weight",
        },
        diameter: {
          fi: "Halkaisija",
          en: "Diameter",
        },
        sku: {
          fi: "Sku",
          en: "Sku",
        },
        numeric_size: {
          fi: "Koko",
          en: "Size",
        },
        in_pack: {
          fi: "",
          en: "",
        },
        tekso_size: {
          fi: "Koko",
          en: "Size",
        },
        measured_size: {
          fi: "Koko",
          en: "Size",
        },
        shoe_size: {
          fi: "Koko",
          en: "Size",
        },
        style: {
          fi: "tyyli",
          en: "Style",
        },
      },

      variantOrder2: [],
      variantList: {},
      variantSelectedList: {},
      selectedProduct: {},
      variantSelect: {},
      variantDisable: false,
      variantSelectedItem: {},
      splitPrice: "",
      splitDecimal: "",
      inStock: true,
      seoNameFound: false,
      newProduct: false,
      discount: false,
      discountPercent: 0,
    };
  },
  metaInfo() {
    return {
      title:
        this.product.name + " - Sisustus ja lahjatavaraliike Loviisan Aitta",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.product.name,
        },
        {
          vmid: "ogtitle",
          property: "og:title",
          content: "Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          property: "og:site_name",
          content: "Loviisan Aitta",
        },
        {
          vmid: "ogdescription",
          property: "og:description",
          content: this.product.name,
        },
        {
          property: "og:type",
          content: "profile",
        },
        {
          property: "og:url",
          content: "https://loviisanaitta.fi" + this.$route.path,
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: this.baseurl + this.imageUUID + "/small.png",
        },
      ],
    };
  },
  async mounted() {
    let data = {
      from: 0,
      size: 100,
      query: { match: { "details.sku.value": this.$route.params.sku } },
    };
    await axios.post(this.url, data).then((response) => {
      this.product = response.data.items[0];
      // console.log(this.product);
      this.generateSlug(this.product.name);

      let check = this.checkDiscountCategory(this.product.categories);

      if (check) {
        this.discount = true;
        this.discountPercent = check.percentage * 100;
      }

      if (this.product.details.price) {
        let value = 0;
        if (this.discount) {
          value =
            this.product.details.price.value *
            (1.0 - (this.discountPercent / 100).toFixed(2));
        } else {
          value = this.product.details.price.value;
        }

        // let temp = this.product.details.price.value.toString();
        let temp = value.toString();
        let priceArray = temp.split(".");
        let price = priceArray[0];
        let decimal = priceArray[1];
        this.splitPrice = price;
        if (isNaN(decimal)) {
          this.splitDecimal = "00";
        } else {
          if (decimal.length < 2) {
            this.splitDecimal = decimal + "0";
          } else {
            this.splitDecimal = decimal.substring(0, 2);
          }
        }
      }
      // if (response.data.items[0].assets) {
      //   this.assets = response.data.items[0].assets;
      //   this.imageUUID = response.data.items[0].assets[0];
      // }

      if ("thumbnail" in response.data.items[0]) {
        if (
          response.data.items[0].thumbnail !== undefined ||
          response.data.items[0].thumbnail !== null
        )
          this.imageUUID = response.data.items[0].thumbnail;
      } else if ("assets" in response.data.items[0]) {
        this.imageUUID = response.data.items[0].assets[0];
      }

      if (response.data.items[0].variants) {
        this.hasVariants = true;
        this.variants = response.data.items[0].variants;

        let tempList = [];
        this.variantOrder.map((e) => {
          let temp = this.findVariant(this.variants, e);
          if (temp.length) {
            tempList.push(e);
          }
        });
        if (tempList.length > 0) {
          tempList.map((e, i) => {
            let data = {
              id: i,
              name: e,
            };
            this.variantOrder2.push(data);
          });
        }

        this.variantOrder2.map((e) => {
          let temp = this.findVariant(this.variants, e.name);

          this.variantOrder.forEach((i) => {
            // console.log(i)
            if (e.name === i) {
              this.variantList[e.id] = temp;
            }
          });

          // if (e.name === "colour") {
          //   this.variantList[e.id] = temp;
          // } else if (e.name === "size_letter_code") {
          //   this.variantList[e.id] = temp;
          // } else if (e.name === "width") {
          //   this.variantList[e.id] = temp;
          //   // } else if (e.name === "length") {
          //   //   this.variantList[e.id] = temp;
          // } else if (e.name === "height") {
          //   this.variantList[e.id] = temp;
          // } else if (e.name === "depth") {
          //   this.variantList[e.id] = temp;
          // } else if (e.name === "weight") {
          //   this.variantList[e.id] = temp;
          // } else if (e.name === "diameter") {
          //   this.variantList[e.id] = temp;
          // }
          // else {
          //   this.variantList[e.id] = temp;
          // }
        });
        this.findSelectedProduct();
      } else {
        this.axios
          .get(this.stockUrl + this.product.uuid)
          .then((res) => {
            // console.log("stock value: " + res.data);
            // console.log(
            //   "product stoxk value: " + this.product.details.in_stock.value
            // );
            this.stock = res.data;
          })
          .finally(() => {
            if (this.$route.query) {
              if (
                Object.keys(this.$route.query).length >=
                this.variantOrder2.length
              ) {
                if (this.stock) {
                  this.variantDisable = false;
                } else {
                  this.variantDisable = true;
                }
              } else {
                this.variantDisable = true;
              }
            }
          });
      }

      this.$store.commit("recentItems", { product: this.product });
      if (this.$route.query) {
        if (
          Object.keys(this.$route.query).length >= this.variantOrder2.length
        ) {
          if (this.stock) {
            this.variantDisable = false;
          } else {
            this.variantDisable = true;
          }
        } else {
          this.variantDisable = true;
        }
      }
    });
    if (this.$route.query) {
      let i = 0;
      for (let query in this.$route.query) {
        this.variantSelect[i] = this.$route.query[query];
        i += 1;
      }
      let a = i - 1;

      this.fixSelection(a.toString);
    }
  },
  methods: {
    seoUrl: function(item) {
      let seoName = this.$store.state.cms.categoryData.filter(
        (i) => i.uuid === item.categories[0]
      );
      if (seoName.length >= 1) {
        return seoName[0].seoName;
      } else {
        return "category-not-found";
      }
    },
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      history.pushState(
        {},
        null,
        (this.$route.params.sku = replaceExtraDashes)
      );
    },
    hideInfo: function(item) {
      if (Object.keys(this.variantNames).includes(item)) {
        return true;
      } else return false;

      // if (item.match(/wp_id|price|in_stock|tax/)) {
      //   return false;
      // }
      // return true;
    },
    hideVariantInfo: function(item) {
      if (item.match(/wp_id|price|in_stock|tax|identifier/)) {
        return false;
      }
      return true;
    },
    fixSelection: function(i) {
      for (let item in this.variantSelect) {
        this.variantDisable = true;
        if (item > parseInt(i)) {
          delete this.variantSelect[item];
        }
      }
      if (
        Object.keys(this.variantSelect).length >=
        Object.keys(this.variantList).length
      ) {
        if (Object.keys(this.variants).length > 0) {
          this.findSelectedProduct();
        }
      }
    },
    variantItems: function(item) {
      // console.log(item);
      let array = [];
      let find = this.variantOrder2.find(
        (element) => element.id === parseInt(item)
      );

      if (find !== undefined) {
        if (parseInt(item) === 0) {
          this.variantList[find.id].map((e) => {
            array.push(e[find.name].value);
          });
        } else {
          let test3 = this.findTest(
            this.variantList[find.id],
            this.variantOrder2[find.id - 1].name,
            this.variantSelect[item - 1]
          );

          test3.map((e) => {
            array.push(e[find.name].value);
          });

          return array;
        }
        this.variantSelectedList[find.id] = array;
        return array;
      }
    },
    variantSelectName: function(item) {
      if (this.variantOrder2[item].name) {
        return this.lang(this.variantNames[this.variantOrder2[item].name]);
      } else {
        return "name not found";
      }
    },
    findTest: function(variant, check, key) {
      let result = [];
      variant.map((e) => {
        if (e[check] !== undefined) {
          if (e[check].value === key) {
            result.push(e);
          }
        }
      });
      return result;
    },

    findVariant: function(variantsToFind, keyToMatch) {
      return this.helper(variantsToFind, keyToMatch, []);
    },

    helper: function(object, key, result) {
      if (!object) return result;
      if (object instanceof Array) {
        for (var i in object) {
          result = result.concat(this.helper(object[i], key, []));
        }
        return result;
      }
      if (object[key]) result.push(object);

      if (typeof object == "object" && object !== null) {
        let children = Object.keys(object);
        if (children.length > 0) {
          for (i = 0; i < children.length; i++) {
            result = result.concat(this.helper(object[children[i]], key, []));
          }
        }
      }
      return result;
    },
    findSelectedProduct: function() {
      let totalSelected = 0;
      for (let x in this.variantSelect) {
        if (x) {
          totalSelected += 1;
        }
      }
      if (totalSelected >= Object.keys(this.variantList).length) {
        this.variants.find((e) => {
          let total = 0;
          this.variantOrder2.map((x) => {
            if (e[x.name]) {
              if (e[x.name].value === this.variantSelect[x.id]) {
                total += 1;
              }
            }
          });
          if (total >= Object.keys(this.variantList).length) {
            // this.selectedProduct = e;
            this.selectedProduct = Object.assign({}, this.selectedProduct, e);
            // console.log(this.selectedProduct);
            this.axios
              // .get(this.stockUrl + this.product.uuid + ":" + e.identifier.value)
              .get(this.stockUrl + e.identifier.value)
              .then((res) => {
                // console.log("variant stock value: " + res.data);
                this.variantStock = res.data;
              })
              .finally(() => {
                if (this.variantStock) {
                  if (this.variantStock > 0) {
                    this.variantDisable = false;
                  } else {
                    this.variantDisable = true;
                  }
                } else {
                  this.variantDisable = true;
                }
              });

            // if (e.in_stock) {
            //   if (e.in_stock.value > 0) {
            //     this.variantDisable = false;
            //   } else {
            //     this.variantDisable = true;
            //   }
            // } else {
            //   this.variantDisable = true;
            // }
          }
        });
      }
    },
    addToCart: function() {
      if (this.hasVariants) {
        let totalSelected = 0;
        for (let x in this.variantSelect) {
          if (x) {
            totalSelected += 1;
          }
        }

        if (totalSelected >= Object.keys(this.variantList).length) {
          this.variants.find((e) => {
            let total = 0;
            this.variantOrder2.map((x) => {
              if (e[x.name]) {
                if (e[x.name].value === this.variantSelect[x.id]) {
                  total += 1;
                }
              }
            });
            if (total >= Object.keys(this.variantList).length) {
              this.selectedProduct = e;
            }
          });

          // console.log(this.selectedProduct);

          if (this.selectedProduct) {
            // if (this.selectedProduct.in_stock) {
            if (this.variantStock) {
              // console.log(this.selectedProduct);
              let variantProduct = [];
              this.variantOrder2.map((item, i) => {
                item = {
                  name: this.lang(this.variantNames[item.name]),
                  value: this.variantSelect[i],
                };
                variantProduct.push(item);
              });
              let data = {};
              if (this.discount) {
                data = {
                  name: this.product.name,
                  sku: this.selectedProduct.sku.value,
                  price: this.selectedProduct.price.value,
                  in_stock: this.variantStock,
                  count: this.count,
                  uuid: this.selectedProduct.identifier.value,
                  assets: this.product.assets,
                  baseProduct: {
                    uuid: this.product.uuid,
                    sku: this.product.details.sku.value,
                  },
                  variantProduct: variantProduct,
                  details: this.product.details,
                  discount: {
                    active: this.discount,
                    percent: this.discountPercent,
                  },
                };
              } else {
                data = {
                  name: this.product.name,
                  sku: this.selectedProduct.sku.value,
                  price: this.selectedProduct.price.value,
                  in_stock: this.variantStock,
                  count: this.count,
                  uuid: this.selectedProduct.identifier.value,
                  assets: this.product.assets,
                  baseProduct: {
                    uuid: this.product.uuid,
                    sku: this.product.details.sku.value,
                  },
                  variantProduct: variantProduct,
                  details: this.product.details,
                };
              }
              // console.log(data);

              if (data.count > 0 && data.count <= data.in_stock) {
                this.selectedProduct.in_stock.value -= data.count;
                this.$store.commit("addToCart", data);
                this.$store.commit("setSnack", {
                  text: this.product.name,
                  message: "lisätty ostoskoriin",
                  color: "success",
                });
              } else {
                this.$store.commit("setSnack", {
                  text: this.product.name,
                  message: "tuotetta ei riittävästi varastossa",
                  color: "error",
                });
              }
            }
          }
        }
      } else if (!this.hasVariants) {
        if (this.stock) {
          let data = {};

          if (this.discount) {
            data = {
              name: this.product.name,
              sku: this.product.details.sku.value,
              price: this.product.details.price.value,
              in_stock: this.stock,
              count: this.count,
              uuid: this.product.uuid,
              assets: this.product.assets,
              details: this.product.details,
              discount: {
                active: this.discount,
                percent: this.discountPercent,
              },
            };
          } else {
            data = {
              name: this.product.name,
              sku: this.product.details.sku.value,
              price: this.product.details.price.value,
              in_stock: this.stock,
              count: this.count,
              uuid: this.product.uuid,
              assets: this.product.assets,
              details: this.product.details,
            };
          }

          if (data.count > 0 && data.count <= data.in_stock) {
            // this.product.details.in_stock.value -= data.count;
            this.$store.commit("addToCart", data);
            // this.$store.commit("setSnack", {
            //   text: this.product.name,
            //   message: "lisätty ostoskoriin",
            //   color: "success",
            // });
          } else {
            this.$store.commit("setSnack", {
              text: this.product.name,
              message: "tuotetta ei riittävästi varastossa",
              color: "error",
            });
          }
        } else {
          this.$store.commit("setSnack", {
            text: this.product.name,
            message: "tuotetta ei tällähetkellä saatavilla",
            color: "error",
          });
        }
      }
    },
    itemCount: function(item) {
      // this.$set(this.count, item,)
      this.count = item;
    },
    realPrice: function(price) {
      let realValue = price;
      // let realValue = price * 100;
      return realValue.toFixed(2);
    },
    addFavorite: function() {
      this.$store.commit("addFavorite", this.product);
      // console.log("added");
    },
    removeFavorite: function() {
      this.$store.commit("removeFavorite", this.product);
      // console.log("removed");
    },
    changeImage: function(uuid) {
      this.imageUUID = uuid;
    },
  },
  computed: {
    ...mapGetters(["itemInFav", "categoryName"]),
  },
  watch: {
    product: function() {
      if (this.product) {
        let tempPrice = this.product.details.price.value.toString();
        let numbArray = tempPrice.split(".");
        this.price = numbArray[0];
        this.priceDecimal = numbArray[1];
      }
    },
    "$store.state.cms.variantOrder": function() {
      this.variantOrder = this.$store.state.cms.variantOrder;
      // console.log("variantOrder updated");
    },
  },
};
</script>
