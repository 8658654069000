<template>
  <div class="d-flex align-center stepper-input stepper-input-product">
    <v-btn
      :disabled="disable"
      @click="countMinus"
      text
      icon
      class="stepper-input__minus-btn"
    >
      <v-icon>mdi-minus</v-icon>
    </v-btn>

    <v-text-field
      v-if="this.item || this.variant"
      outlined
      hide-details
      dense
      type="number"
      :value="this.count"
      @input="onChange($event)"
      class="flex-grow-0 flex-shrink-0 stepper-input__text-field"
    ></v-text-field>
    <v-text-field
      v-else
      :disabled="disable"
      class="flex-grow-0 flex-shrink-0 stepper-input__text-field"
      dense
      hide-details
      outlined
      type="number"
    >
    </v-text-field>

    <v-btn
      :disabled="disable"
      @click="countPlus"
      text
      icon
      class="stepper-input__plus-btn"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: false,
      type: Object,
    },
    variant: {
      required: false,
      type: Object,
    },
    disable: {
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    count: 1,
    productCount: 1,
  }),
  mounted() {
    // console.log("stepper mounted");

    if (this.item) {
      if (this.item.details.in_stock) {
        this.productCount = this.item.details.in_stock.value;
      }
    } else if (this.variant) {
      if (this.variant.in_stock) {
        this.productCount = this.variant.in_stock.value;
      }
    }
  },
  methods: {
    onChange: function (event) {
      if (event < this.productCount) {
        this.count = parseInt(event);
        this.$emit("itemCount", this.count);
      }
    },
    countMinus: function () {
      if (this.count > 1) {
        this.count -= 1;
        this.$emit("itemCount", this.count);
      }
    },
    countPlus: function () {
      if (this.count < this.productCount) {
        this.count += 1;
        this.$emit("itemCount", this.count);
      }
    },
  },
};
</script>
<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.stepper-input .v-btn {
  height: 32px !important;
  width: 32px !important;
}

.stepper-input .v-text-field {
  height: 32px !important;
  width: 48px;
  font-size: 0.874rem;
}

.stepper-input .v-input__slot {
  min-height: 32px !important;
}
</style>
